<!-- 数据统计-->
<template>
  <div id="energyPointStatistics" v-loading="loading" ref="main" :element-loading-text="$t('alarm.a0')">
    <!-- 页面标题 -->
    <div class="energyPointStatistics-title">
      <span>{{ $t("energy.enedata001") }}</span>
    </div>
    <a-row style="height: calc(100% - 30px);">
        <a-col :span="5" style="height: 100%;">
          <template>
              <div style="" class="point-tree-box">                  
                <div style="display:none;"  class="display-between">  
                  <div class="display-column">
                    <span>Gr1</span>
                    <a-select v-model="data.gr1" @change="handleGrChange(1)">
                      <a-select-option
                          v-for="(itme, index) in data.gr1s"
                          :key="index"
                          :value="itme.id"
                        >
                          {{ itme.name }}
                        </a-select-option>
                    </a-select>
                  </div>
                  <div class="display-column">
                    <span>Gr2</span>
                    <a-select v-model="data.gr2" @change="handleGrChange(2)">
                      <a-select-option
                          v-for="(itme, index) in data.gr2s"
                          :key="index"
                          :value="itme.id"
                        >
                          {{ itme.name }}
                        </a-select-option>
                    </a-select>
                  </div>
                  <div class="display-column">
                    <span>Gr3</span>
                    <a-select v-model="data.gr3" @change="handleGrChange(3)">
                      <a-select-option
                          v-for="(itme, index) in data.gr3s"
                          :key="index"
                          :value="itme.id"
                        >
                          {{ itme.name }}
                        </a-select-option>
                    </a-select>
                  </div>
                </div>
                <a-input-search style="margin-bottom: 8px" placeholder="查询" @change="queryPoints" />
                <a-checkbox :indeterminate="indeterminate" :checked="checkAll" @change="onCheckAllChange">
                  {{ $t("energy.enedata025") }}
                </a-checkbox>
                <a-tree
                  style=""
                  class="point-tree"
                  checkable
                  show-line
                  v-model="checkedPoints"
                  :tree-data="treePointLists"
                  :selectable="true"
                  :multiple="true"
                  :replaceFields="{children:'children', title:'text', key:'no' }"
                  @select="pointSelect"
                  @check="pointCheck"
                >
                  <a-icon slot="switcherIcon" type="down" />
                </a-tree>
              </div>
          </template>
        </a-col>
        <a-col :span="15" style="padding: 0 2px;height: 100%;">
             <!-- 查询条件1 -->
            <div class="display-between inquire">
              <div class="display-between-2 inquire-item">
                <div class="display-column">
                  <!-- 类型 -->
                  <span>{{ $t("energy.enedata002") }}</span>
                  <a-radio-group v-model="rpt" button-style="solid" @change="rptChange">
                    <a-radio-button  v-for="(itme, index) in this.rptKinds"
                        :key="index"
                        :value="itme.no"> {{ itme.text }}</a-radio-button>
                  </a-radio-group>
                </div>
                <!-- 计量对象 -->
                <!-- <div class="display-column">
                  <span>{{ $t("energy.enedata003") }}</span>
                  <a-select v-model="point" style="width: 360px" @change="handleChange">
                    <a-select-option
                        v-for="(itme, index) in this.pointLists"
                        :key="index"
                        :value="itme.no"
                        >
                        {{ itme.text }}
                      </a-select-option>
                  </a-select>
                </div>  -->
                <div class="display-column">
                  <!-- 显示对象 -->
                  <span>{{ $t("energy.enedata004") }}</span>
                  <a-radio-group v-model="chartKind" button-style="solid" @change="drawMyChart">
                    <a-radio-button value="line">{{ $t("energy.enedata013") }}</a-radio-button>
                    <a-radio-button value="bar">{{ $t("energy.enedata014") }}</a-radio-button>
                    <a-radio-button value="pie">{{ $t("energy.enedata015") }}</a-radio-button>
                  </a-radio-group>
                </div>

                
              </div>
              <a-button type="primary" @click="getRptData">{{ $t("energy.enedata006") }}</a-button>
              <!-- 查询 -->
            </div>
            <div style="height: calc(100% - 60px);background:#7682CE;">
                <template> 
                  <a-carousel arrows>
                    <div slot="prevArrow"  class="custom-slick-arrow" style="left: 10px;zIndex: 1;">
                      <a-icon type="left-circle" />
                    </div>
                    <div slot="nextArrow" class="custom-slick-arrow" style="right: 10px">
                      <a-icon type="right-circle" />
                    </div>

                    <div style="height:100%;">
                      <div class="chart-box">
                        <div id="myChart" :style="myChartSize" ></div>
                      </div>

                    </div>
                      <div style="height:100%;">
                      <div class="table-box">
                          <a-table
                              :columns="myStatisticsData.tableData.columns"
                              :data-source="myStatisticsData.tableData.data"
                              :pagination="pagination"
                              :scroll="Yscroll"
                              size="small"
                            />
                          </div>
                      </div>
                  </a-carousel>
                </template>
            </div>
        </a-col>
         <a-col :span="4" style="height: 100%;">
            <div class="point-date-box">
                <div class="point-date" v-if="rpt=='3'">
                  <div class="display-column">
                    <!-- 目标年度 -->
                    <span>{{ $t("energy.enedata005") }}</span>
                    <!--  @change="yearChange" -->
                    <a-select v-model="year"  :mode="mode" style="width: 120px"> 
                      <a-select-option
                          v-for="(itme, index) in this.yearList"
                          :key="index"
                          :value="itme"
                          >
                          {{ itme }}
                        </a-select-option>
                    </a-select>
                  </div>
                  <div class="display-column" >
                      <span>{{$t("energy.enedata398")}}</span>
                      <a-switch v-model="msel" @change="mselChange" :style="{'width':'60px','margin-top':'5px'}" />
                  </div>
                  <div class="display-column">
                      <span>{{$t("energy.enedata399")}}</span>
                      <a-select v-model="month" style="width: 120px" :disabled="defaultStatistic==0" >
                        <a-select-option
                            v-for="(itme, index) in this.monthList"
                            :key="index"
                            :value="itme.no"
                            >
                            {{ itme.text }}
                          </a-select-option>
                      </a-select>
                  </div>
                </div>
                <div class="point-date"  v-if="rpt=='2'">
                    <div class="date-box1">
                      <span>{{ $t("energy.enedata005") }}：</span>
                      <a-select v-model="year" style="width: 120px" @change="monthRptYearChange"  :disabled="this.checkedPoints.length!=1">
                        <a-select-option
                            v-for="(itme, index) in this.yearList"
                            :key="index"
                            :value="itme"
                            >
                            {{ itme }}
                          </a-select-option>
                      </a-select>
                    </div>
                    <div class="date-box">月份1：<a-month-picker  v-model="mon" style="width:120px;"/></div>
                    <div class="date-box">月份2：<a-month-picker  v-model="tue" :disabled="this.checkedPoints.length!=1" style="width:120px;"/></div>
                    <div class="date-box">月份3：<a-month-picker  v-model="wed" :disabled="this.checkedPoints.length!=1" style="width:120px;"/></div>
                    <div class="date-box">月份4：<a-month-picker  v-model="thu" :disabled="this.checkedPoints.length!=1" style="width:120px;"/></div>
                    <div class="date-box">月份5：<a-month-picker  v-model="fri" :disabled="this.checkedPoints.length!=1" style="width:120px;"/></div>
                    <div class="date-box">月份6：<a-month-picker  v-model="sat" :disabled="this.checkedPoints.length!=1" style="width:120px;"/></div>
                    <div class="date-box">月份7：<a-month-picker  v-model="sun" :disabled="this.checkedPoints.length!=1" style="width:120px;"/></div>
                    <div class="date-box">月份8：<a-month-picker  v-model="mon8" :disabled="this.checkedPoints.length!=1" style="width:120px;"/></div>
                    <div class="date-box">月份9：<a-month-picker  v-model="mon9" :disabled="this.checkedPoints.length!=1" style="width:120px;"/></div>
                    <div class="date-box">月份10：<a-month-picker  v-model="mon10" :disabled="this.checkedPoints.length!=1" style="width:120px;"/></div>
                    <div class="date-box">月份11：<a-month-picker  v-model="mon11" :disabled="this.checkedPoints.length!=1" style="width:120px;"/></div>
                    <div class="date-box">月份12：<a-month-picker  v-model="mon12" :disabled="this.checkedPoints.length!=1" style="width:120px;"/></div>
                </div>
                <div class="point-date"  v-if="rpt=='1'">


                    <div class="date-box1">选择周：<a-week-picker placeholder="选择周" v-model="week" @change="weekChange" :disabled="this.checkedPoints.length!=1"/></div>
                    <div class="date-box">日期1：<a-date-picker  v-model="mon"/></div>
                    <div class="date-box">日期2：<a-date-picker  v-model="tue" :disabled="this.checkedPoints.length!=1"/></div>
                    <div class="date-box">日期3：<a-date-picker  v-model="wed" :disabled="this.checkedPoints.length!=1"/></div>
                    <div class="date-box">日期4：<a-date-picker  v-model="thu" :disabled="this.checkedPoints.length!=1"/></div>
                    <div class="date-box">日期5：<a-date-picker  v-model="fri" :disabled="this.checkedPoints.length!=1"/></div>
                    <div class="date-box">日期6：<a-date-picker  v-model="sat" :disabled="this.checkedPoints.length!=1"/></div>
                    <div class="date-box">日期7：<a-date-picker  v-model="sun" :disabled="this.checkedPoints.length!=1"/></div>
                </div>
            </div>
        </a-col>
    </a-row>
   
     <!-- 信号点使用量修改 -->
     <a-modal  v-model="visible" :title="$t('energy.enedata568')" :ok-text="$t('energy.enedata023')" :width="700"
     :okButtonProps="{props:{disabled:!fixPointInfo.modify,loading:floading}}" :cancelButtonProps="{props:{disabled:floading}}" @ok="saveChange">
      <div class="fix-modal" style="width:640px; height:440px; overflow: hidden;" v-loading="floading" :element-loading-text="$t('alarm.a0')">
       <a-form-model ref="ruleForm" 
            :model="fixPointInfo" 
            :rules="rules" >
      <a-descriptions title="" layout="vertical" size="small" bordered :column="6">
        <!-- 信号点名称 -->
        <a-descriptions-item :label="$t('energy.enedata003')" :span="6">
          {{ fixPointInfo.pointName}}
          <!-- <a-badge status="processing" text="Running" /> -->
        </a-descriptions-item>
        <!-- 时间 -->
        <a-descriptions-item :label="$t('energy.enedata016')"  :span="2">
           {{ fixPointInfo.date}}
        </a-descriptions-item>
        <!--  类型-->
        <a-descriptions-item :label="$t('energy.enedata002')" :span="2">
         {{ getReportType(fixPointInfo.reportType)}}
        </a-descriptions-item>

        <!-- 单位  -->
        <a-descriptions-item :label="$t('energy.enedata113')" :span="2">
         {{ fixPointInfo.unit}}
        </a-descriptions-item>

        <!--使用量  -->
        <a-descriptions-item :label="$t('energy.enedata009')" :span="2">
           <a-form-model-item  ref="value" prop="value">
              <a-input v-model="fixPointInfo.value"  @change="pointAllValueChange" :disabled="(fixPointInfo.fixMode || ((fixPointInfo.pointType == 4 || fixPointInfo.pointType == 5) && rpt != '1'))" style="width:100%;"/>
           </a-form-model-item>
        </a-descriptions-item>
        

         <!--使用量  -->
        <a-descriptions-item :label="$t('energy.enedata573')" :span="2">
          <a-form-model-item  ref="dayValue" prop="dayValue">
              <a-input v-model="fixPointInfo.dayValue"  @change="pointDayValueChange" :disabled="fixPointInfo.fixMode || fixPointInfo.pointType ==6 || rpt=='1'" style="width:100%;"/>
          </a-form-model-item>
        </a-descriptions-item>


         <!--使用量  -->
        <a-descriptions-item :label="$t('energy.enedata574')" :span="2">
          <a-form-model-item  ref="nightValue" prop="nightValue">
              <a-input v-model="fixPointInfo.nightValue" @change="pointNightValueChange" :disabled="fixPointInfo.fixMode || fixPointInfo.pointType ==6 || rpt=='1'" style="width:100%;"/>
          </a-form-model-item>
        </a-descriptions-item>
       
        
        <!-- 
          使用量修改模式
          自动
          手动
          :disabled="point.pointType == 6 && this.rpt != '1'" 
         -->
        <a-descriptions-item :label="$t('energy.enedata569')"  :span="2" >
            <a-switch :checked-children="$t('energy.enedata570')" :un-checked-children="$t('energy.enedata571')" :disabled="fixPointInfo.pointType == 6 && rpt == '1'"  v-model="fixPointInfo.fixMode" />
        </a-descriptions-item>

        <!-- 
          数据来源
          设备
          数据库

         -->
        <a-descriptions-item :label="$t('energy.enedata578')"  :span="2" >
            <a-switch :checked-children="$t('energy.enedata580')" :un-checked-children="$t('energy.enedata579')" :disabled="fixPointInfo.pointType==6 || !fixPointInfo.fixMode || rpt=='1'" v-model="fixPointInfo.fixAutoMode" />
        </a-descriptions-item>
        <!-- 
         同步更新数据

         -->
        <a-descriptions-item :label="$t('energy.enedata581')"  :span="2" >
            <a-switch :checked-children="$t('energy.enedata582')" :un-checked-children="$t('energy.enedata583')" :disabled="rpt=='3' || discascade" v-model="fixPointInfo.cascade" />
        </a-descriptions-item>
      </a-descriptions>
       </a-form-model>
      <!-- 当前日期和未来日期的使用量不可修改。 style="color:#c41d7f;padding:5px;" discascade -->
      <ul style="font-size:12px;margin-top:5px;">
        <li >{{$t('energy.enedata572')}}</li>
        <li >{{$t('energy.enedata575')}}{{$t('energy.enedata576')}}{{$t('energy.enedata577')}}</li>
        <li >{{$t('energy.enedata584')}}</li>
        <!-- 同步更新数据:修改每小时数据，同步更新每日和每月的数据，当天和当月数据除外。修改每日数据，同步更新每月的数据，当月数据除外。修改每月数据无同步更新数据。 -->
      </ul>
        <!-- <pre class="fix-modal-content" style=" width: 100%;height: 100%;overflow: auto;">  {{ fixPointInfo }}</pre> -->
      </div>
    </a-modal>
  </div>
</template>

<script>
import { getEnergyStatisticsData ,downloadEnergyStatisticsData,getEnergyPointGrPathList,fixUpdateEnergyData } from "../../../api/energy";
import SubMenu from "../components/measureGroup/eneMeasureGroupSubMenu.vue";
import moment from "moment";
const colors=['#7682CE','#d1b7dd','#86c9e3','#99d080','#607aca','#fc8958','#ef6b6b','#f7df1e'];
import { EleResize } from "../../../utils/esresize";
export default {
  data() {
    let numberVail = (rule, value, callback) => {
        let val = new Number(value);
        console.log(val);
        if(value<0) {
            callback(new Error(rule.message));
        }else if(value>999999999) {
            callback(new Error(rule.message));
        } else {
            callback();
        }
    };

    let numberVail2 = (rule, value, callback) => {
        let val = new Number(value);
        console.log(val);
        if(val<0) {
            callback(new Error(rule.message));
        }else if(val>1999999998) {
            callback(new Error(rule.message));
        } else {
            callback();
        }
    };

    return {
      visible:false,
      loading:false,
      rpt: "3",
      chartKind: "line",
      point: "",
      year:undefined,
      fiscalYear:undefined,
      rptKinds:[],
      pointLists:[],
      pointListsGr:[],
      treePointLists:[],
      treePointNoLists:[],
      dataLists:[],
      chartData:null,
      yearList:[],
      date:"",
      measurePoint:null,
      ycategorys:[],
      mcategorys:[],
      dcategorys:[],
      columns:[],
      pagination:false,
      Yscroll: { x: 1100,y:240 },
      difPoint:false,
      difYear:false,
      msel:false,
      defaultStatistic:0,
      month:"1",
      monthList:[],
      monthMap:null,
      checkedPoints:[],
      week:moment(),
      mon:moment(),
      tue:undefined,
      wed:undefined,
      thu:undefined,
      fri:undefined,
      sat:undefined,
      sun:undefined,
      mon8:undefined,
      mon9:undefined,
      mon10:undefined,
      mon11:undefined,
      mon12:undefined,
      days:[moment()],
      myStatisticsData:{
        tableData:{
          columns:[],
          data:[]
        },
        chartData:{
            title:"",
            unit:"",
            xunit:"",
            yunit:"",
            legends: Array(),
            categorys: Array(),
            series: Array(),
            accumSeries: Array(),
            forecastSeries: Array(),
            visualMap:undefined,
        },
        totalData:[],
      },
      myChartSize:{width: '100%', height: '0px'},
      mode:"multiple",
      indeterminate:false,
      checkAll:false,
      myChart:undefined,
      data:{
          measurementList:[],
          measurement:'',
          dateList:[],
          summer:'',
          winter:'',
          choicePointList:[],
          gr1:'',
          gr1s:[],
          gr2:'',
          gr2s:[],
          gr3:'',
          gr3s:[],
          pointList:[],
          unit:'',
      },
      rptKinds:[{no:"3",text:"每月",},
                {no:"2",text:"每日",},
                {no:"1",text:"每小时",}],

      measurementStatisticsData:[],
      fixPointInfo:{fixMode:false,fixAutoMode:false,cascade:true},
      initfixPointInfo:{fixMode:false,fixAutoMode:false,cascade:true},
      floading:false,
      discascade:false,
      rules: {
          dayValue: [
              { required: true, message: this.$t('energy.enedata599'), trigger: 'blur' },
              { validator:numberVail, trigger: 'blur' , message: this.$t('energy.enedata602')},
          ],
          nightValue: [
              { required: true, message: this.$t('energy.enedata600'), trigger: 'blur' },
              { validator:numberVail, trigger: 'blur', message: this.$t('energy.enedata603') },
          ],
          value: [
              { required: true, message: this.$t('energy.enedata601'), trigger: 'blur' },
              { validator:numberVail2, trigger: 'blur', message: this.$t('energy.enedata604') },
          ],
        }, 
    };
  },
  activated(){
    console.log("activated",this.$store.getters.clientId);
    if(this.$store.getters.clientId){
        console.log("activated load data");
        this.initPage();
    }
  },
  mounted() {

    // this.initPage();
    //实现自适应部分
    this.resizeChart();
    window.addEventListener("resize", () => this.resizeChart());
    
  },
  methods: {
    pointAllValueChange(obj){
        let val = new Number(obj.target.value);
        if(isNaN(val)){
          this.fixPointInfo.value = this.initfixPointInfo.value;
          return;
        }
    },
    pointDayValueChange(obj){
        let val = new Number(obj.target.value);
        if(isNaN(val) ){
          this.fixPointInfo.dayValue = this.initfixPointInfo.dayValue;
          return;
        }
        this.pointValueChange();
    },
    pointNightValueChange(obj){
        let val = new Number(obj.target.value);
        if(isNaN(val) ){
          this.fixPointInfo.nightValue = this.initfixPointInfo.nightValue;
          return;
        }
        this.pointValueChange();
    },
    pointValueChange(){
        this.fixPointInfo.value = parseFloat(this.fixPointInfo.dayValue) + parseFloat(this.fixPointInfo.nightValue);
    },
    handleGrChange(gr){
      let id=0;
      if(gr==1){        
        id=this.data.gr1;
        if(id>0) { //不为空 
          this.data.gr2s.length=0;
          this.data.gr3s.length=0;
          let first = {id:0,name:""};
          this.data.gr2s.push(first); 
          this.data.gr2 = 0;
          this.data.gr3 = "";
          for(let i=0;i<this.pointListsGr.length;i++){
            if(this.pointListsGr[i].hierarchyDepth==3&&this.pointListsGr[i].parentId==this.data.gr1){
              this.data.gr2s.push(this.pointListsGr[i]);
            }
          }
          console.log("data.gr2s:",this.data.gr2s);
        }
        else
          this.treePointLists= [...this.pointLists];
      }else if(gr==2){
        id= this.data.gr2;  
        if(id>0) { //不为空   
          this.data.gr3s.length=0;
          let first = {id:0,name:""};
          this.data.gr3s.push(first); 
          this.data.gr3 = 0;
          for(let i=0;i<this.pointListsGr.length;i++){
            if(this.pointListsGr[i].hierarchyDepth==4&&this.pointListsGr[i].parentId==this.data.gr2){
              this.data.gr3s.push(this.pointListsGr[i]);
            }
          }
        }
        else
          this.handleGrChange(1);
      }
      else{
        id=this.data.gr3;
        if(id==0) { //不为空 
          this.handleGrChange(2);
        }
      }
      if(id>0) //不为空 
        this.filterData(id);
    },
    filterData(id){
      if(id>0){
          const idStr=id+"";
          console.log("filterData id",idStr);
          const points = [...this.pointLists];
          
          this.treePointLists = points.filter(function(item){
              if(new String(item.pointInfo).indexOf(idStr)>=0){
                
                return true;
              }
          });
          this.treePointNoLists.length=0;
          for(let i=0;i<this.treePointLists.length;i++){
            this.treePointNoLists.push(this.treePointLists[i].no);
          }
          console.log("queryPoints treePointLists",this.treePointLists);
      }
    },
    onCheckAllChange(e){
      Object.assign(this, {
        checkedPoints: e.target.checked ? this.treePointNoLists : [],
        indeterminate: false,
        checkAll: e.target.checked,
      });
      this.clearPointDate();
    },
    resizeChart(){
      // if(document.getElementById("myChart")){ 
      //    this.$echarts.init(document.getElementById("myChart")).resize();
      // }
      if(this.$refs.main){
        let height = this.$refs.main.clientHeight;
        let tableHeight = height - 180;
        console.log("resizeChart=",tableHeight);
        this.myChartSize.height = (height-160)+"px";
        this.Yscroll.y = tableHeight-20;
      }
    },
    initPage(){
      this.getEnergyPointGrPathList();    
      this.getEnergyInitData();
      
    },
    getEnergyInitData(){
      let params = {
        action:0,
        clientId: this.$store.getters.clientId,
        sitegrId: this.$store.getters.sitegrId,
        siteId: this.$route.query.id,
        tenantId: 0,
      };
      console.log(params);
      this.loading = true;
    
      getEnergyStatisticsData(params)
      .then((res) => {
        console.log(res);
        this.loading = false;
        this.rptKinds = res.data.rptKinds;
        this.pointLists = res.data.measurementPointList;
        this.treePointLists = [...this.pointLists];
        this.rptKinds = res.data.rptKinds;
        for(let i=0;i<this.treePointLists.length;i++){
          this.treePointNoLists.push(this.treePointLists[i].no);
        }

        this.point = this.pointLists[0].no;
        this.yearList = res.data.yearList;
        this.fiscalYear = res.data.fiscalYear;
        this.year = res.data.fiscalYear;
        this.ycategorys = res.data.ycategorys;
        this.mcategorys = res.data.mcategorys;
        this.dcategorys = res.data.dcategorys;
        this.monthList  = res.data.monthList;
        this.drawDefultChart();        
      }).catch((err) => {
        this.loading = false;
        console.log(err);
      });
    },

    //取GR1、GR2、GR3
    getEnergyPointGrPathList(){
      let par={
        siteId:this.$route.query.id,
      };
      console.log("getEnergyPointGrPathList par:",par);      
      getEnergyPointGrPathList(par)
      .then((res) => {
        console.log("getEnergyPointGrPathList res:",res);  
        let list =res.data;
        if(list!=null && list.length>0){
          this.data.gr1s.length=0;
          let first = {id:0,name:""};
          this.data.gr1s.push(first); 
          this.pointListsGr.length=0;
          for(let i=0;i<list.length;i++){
            if(list[i].groupType==1){
              this.pointListsGr.push(list[i]);
              if(list[i].hierarchyDepth==2){//二阶层
                this.data.gr1s.push(list[i]);
              }
            }
          }          
        }
      }).catch((err) => {
        this.loading = false;
        console.log(err);
      });
    },
    getRptData(){
      let measurePointIds = new Array();
      if(this.checkedPoints==null || this.checkedPoints.length<=0){
        return;
      }
      measurePointIds = [...this.checkedPoints];
      let yearList = new Array();
      let dataTimes = new Array();
      let v = parseInt(this.rpt);
      if(v==3){
        if(this.year.length){
          yearList.push(...this.year);
        }else{
          yearList.push(this.year);
        }
      }else if(v==2){
         this.days = new Array();
        if(this.mon){
          this.days.push(this.mon)
        }
        if(this.tue){
          this.days.push(this.tue)
        }
        if(this.wed){
          this.days.push(this.wed)
        }
        if(this.thu){
          this.days.push(this.thu)
        }
        if(this.fri){
          this.days.push(this.fri)
        }
        if(this.sat){
          this.days.push(this.sat)
        }
        if(this.sun){
          this.days.push(this.sun)
        }

        if(this.mon8){
          this.days.push(this.mon8)
        }
        if(this.mon9){
          this.days.push(this.mon9)
        }
        if(this.mon10){
          this.days.push(this.mon10)
        }
        if(this.mon11){
          this.days.push(this.mon11)
        }
        if(this.mon12){
          this.days.push(this.mon12)
        }
        if(this.days==null || this.days.length<=0){
            return;
        }
        this.days.forEach(function(item){
            dataTimes.push(item.format('YYYY/MM'));
        });
      }else{
        this.days = new Array();
        if(this.mon){
          this.days.push(this.mon)
        }
        if(this.tue){
          this.days.push(this.tue)
        }
        if(this.wed){
          this.days.push(this.wed)
        }
        if(this.thu){
          this.days.push(this.thu)
        }
        if(this.fri){
          this.days.push(this.fri)
        }
        if(this.sat){
          this.days.push(this.sat)
        }
        if(this.sun){
          this.days.push(this.sun)
        }
        if(this.days==null || this.days.length<=0){
            return;
        }
        this.days.forEach(function(item){
            dataTimes.push(item.format('YYYY/MM/DD'));
        });
      }

      let params = {
        action:1,
        clientId: this.$store.getters.clientId,
        siteId: this.$route.query.id,
        sitegrId: this.$store.getters.sitegrId,
        tenantId: 0,
        reportType: this.rpt,
        measurePointIds: measurePointIds,
        yearList:yearList,
        dataTimes:dataTimes,
        defaultStatistic:this.defaultStatistic,
        month:this.month
      };
      console.log(params);
      this.loading = true;
    
      getEnergyStatisticsData(params)
      .then((res) => {
        console.log("getEnergyStatisticsData result:",res);
        this.loading = false;
        // this.emptyShow = "hide";
        // this.dataShow = "show";

        if(res.data.measurementStatisticsDataList){
          this.measurementStatisticsData = res.data.measurementStatisticsDataList;
          let accumDatas = new Array();
          let forecastDatas = new Array();
          let tableDatas = new Array();
          let pointChartDatas = new Array();
          let totalData = new Array();
          let xunit = "";
          let yunit = "";
          let unit  = "";
          let columns = new Array();
          let categories = new Array();
          let rtv = parseInt(this.rpt);
          columns.push({
              "title": this.$t('energy.enedata441'),//"设备/信号名称",
              "dataIndex": "name",
              "key": "name",
              "width": 250,
              "fixed": "left"
          });
          if(res.data.measurementStatisticsDataList.length>0){
            const sData = res.data.measurementStatisticsDataList[0];
           

            if(rtv===3){
              if(sData.energyYearlyDatas.length>0 && sData.energyYearlyDatas[0].currentYear){
                  xunit = sData.energyYearlyDatas[0].currentYear.xunit;
                  yunit = sData.energyYearlyDatas[0].currentYear.yunit;
                   unit = sData.energyYearlyDatas[0].currentYear.unit;
                  categories.push(...sData.energyYearlyDatas[0].currentYear.categories);
              }
            }else if(rtv===2){
               if(sData.monthlyDatas.length>0){
                    xunit = sData.monthlyDatas[0].currentMonth.xunit;
                    yunit = sData.monthlyDatas[0].currentMonth.yunit;
                    unit = sData.monthlyDatas[0].currentMonth.unit;
                    let len = sData.monthlyDatas[0].currentMonth.categories.length;
                    let ecgs =[...sData.monthlyDatas[0].currentMonth.categories];
                    if(sData.monthlyDatas.length>1){
                      for (let l = 1; l < sData.monthlyDatas.length; l++) {
                        let element = sData.monthlyDatas[l];
                        if(element.currentMonth.categories.length>len){
                           ecgs = element.currentMonth.categories;
                           len = element.currentMonth.categories.length;
                        }
                      }
                    }
                    categories.push(...ecgs);
                }
            }else if(rtv===1){
                if(sData.energyDatas.length>0){
                    xunit = sData.energyDatas[0].xunit;
                    yunit = sData.energyDatas[0].yunit;
                    unit = sData.energyDatas[0].unit;
                    categories.push(...sData.energyDatas[0].categories);
                }
            }

             columns.push(...sData.columns);

          }
          this.myStatisticsData.tableData.columns = [...columns];
          this.myStatisticsData.chartData.categorys = [...categories];
          this.myStatisticsData.chartData.xunit = xunit; 
          this.myStatisticsData.chartData.yunit = yunit;
          this.myStatisticsData.chartData.unit  = unit;
          let tkey = 1;
          
          for (let index = 0; index < res.data.measurementStatisticsDataList.length; index++) {
              const sData = res.data.measurementStatisticsDataList[index];
              const point = sData.point;
              const pname = point.pointName;
              const pid = point.id;
              const ptype = point.pointType;

              if(rtv==3){ 
                  for(let i=0;i<sData.energyYearlyTableDatas.length;i++){
                      const tditem = sData.energyYearlyTableDatas[i];
                      if(yearList.length>1 && tableDatas.filter(function(item){
                          return item.date === tditem.date;
                      }).length>0){
                        continue;
                      }
      
                      if(yearList.filter(function(item){
                          if(new String(tditem.date).indexOf(item+'')>=0){
                            return true;
                          }
                      }).length===0){
                        continue;
                      }
                      tditem.name = pname;
                      tditem.key = tkey;
                      tableDatas.push(tditem);

                      totalData.push({
                          key:tkey,
                          id:pid,
                          kind:tditem.date,
                          name:pname,
                          total:tditem.total,
                          unit:tditem.unit
                      });
                      tkey++;
                  }

                  for(let i=0;i<sData.energyYearlyDatas.length;i++){
                    if(sData.energyYearlyDatas[i].currentYear){
                        const yditem = sData.energyYearlyDatas[i].currentYear;
                        yditem.pointName  = pname;
                        yditem.pointId    = pid;
                        yditem.pointType  = ptype;
                        yditem.categories = null;
                        yditem.type =  this.charType;
                        yditem.showBackground = true;
                        pointChartDatas.push(yditem);
                    }
                    
                    if(yearList.length===1 && sData.energyYearlyDatas[i].lastYear){
                        const yditem = sData.energyYearlyDatas[i].lastYear;
                        yditem.pointName  = pname;
                        yditem.pointId    = pid;
                        yditem.pointType  = ptype;
                        yditem.categories = null;
                        yditem.type =  this.chartKind;
                        yditem.showBackground = true;
                        pointChartDatas.push(yditem);
                    }
                  }
                
                // tmpChartDatas.push(...statisticsData.energyYearlyDatas)
              }else if(rtv==2){
                for(let i=0;i<sData.energyMonthlyTableDatas.length;i++){
                      const tditem = sData.energyMonthlyTableDatas[i];
                      tditem.name = pname;
                      tditem.key = tkey;
                      tableDatas.push(tditem);

                      totalData.push({
                          key:tkey,
                          id:pid,
                          kind:tditem.date,
                          name:pname,
                          total:tditem.total,
                          unit:tditem.unit
                      });
                      tkey++;
                  }

                  for(let i=0;i<sData.monthlyDatas.length;i++){
                    let mData = sData.monthlyDatas[i];
                    if(mData.currentMonth){
                        let yditem1 = mData.currentMonth;
                        yditem1.pointName  = pname;
                        yditem1.pointId    = pid;
                        yditem1.pointType  = ptype;
                        yditem1.categories = null;
                        yditem1.type =  this.charType;
                        yditem1.showBackground = true;
                        pointChartDatas.push(yditem1);
                    }
                    
                    if(mData.currentMonthAccumulated && !mData.monthAccumulatedForecast){
                        let yditem2 = mData.currentMonthAccumulated;
                        yditem2.pointName  = pname;
                        yditem2.pointId    = pid;
                        yditem2.pointType  = ptype;
                        yditem2.categories = null;
                        yditem2.type =  "line";
                        yditem2.showBackground = true;
                        yditem2.yAxisIndex = 1;
                        accumDatas.push(yditem2);
                    }

                    if(mData.currentMonthOfLastYear){
                        let yditem3 = mData.currentMonthOfLastYear;
                        yditem3.pointName  = pname;
                        yditem3.pointId    = pid;
                        yditem3.pointType  = ptype;
                        yditem3.categories = null;
                        yditem3.type =  this.chartKind;
                        yditem3.showBackground = true;
                        pointChartDatas.push(yditem3);
                    }

                    if(mData.currentMonthOfLastYearAccumulated){
                        let yditem4 = mData.currentMonthOfLastYearAccumulated;
                        yditem4.pointName  = pname;
                        yditem4.pointId    = pid;
                        yditem4.pointType  = ptype;
                        yditem4.categories = null;
                        yditem4.type =  "line";
                        yditem4.showBackground = true;
                        yditem4.yAxisIndex = 1;
                        accumDatas.push(yditem4);
                    }

                    if(mData.monthAccumulatedForecast){
                        let yditem5 = mData.monthAccumulatedForecast;
                        yditem5.pointName  = pname;
                        yditem5.pointId    = pid;
                        yditem5.pointType  = ptype;
                        yditem5.categories = null;
                        yditem5.type =  "line";
                        yditem5.showBackground = true;
                        yditem5.yAxisIndex = 1;
                        forecastDatas.push(yditem5);
                        accumDatas.push(yditem5);
                    }
                  }
              }else if(rtv==1){
                  for(let i=0;i<sData.energyHourlyTableDatas.length;i++){
                      const tditem = sData.energyHourlyTableDatas[i];
                      tditem.name = pname;
                      tditem.key = tkey;
                      tableDatas.push(tditem);

                      totalData.push({
                          key:tkey,
                          id:pid,
                          kind:tditem.date,
                          name:pname,
                          total:tditem.total,
                          unit:tditem.unit
                      });
                      tkey++;
                  }

                  for(let i=0;i<sData.energyDatas.length;i++){
                      const yditem = sData.energyDatas[i];
                      yditem.pointName  = pname;
                      yditem.pointId    = pid;
                      yditem.pointType  = ptype;
                      yditem.categories = null;
                      yditem.type =  this.chartKind;
                      yditem.showBackground = true;
                      pointChartDatas.push(yditem);
                    
                  }
                // tableDatas.push(...statisticsData.energyHourlyTableDatas);
                // tmpChartDatas.push(...statisticsData.energyDatas)
              } 
          }
          console.log("pointChartDatas>>",pointChartDatas)
          this.myStatisticsData.tableData.data = [...tableDatas];
          this.myStatisticsData.totalData = [...totalData];
          this.myStatisticsData.chartData.series = [...pointChartDatas];
          this.myStatisticsData.chartData.accumSeries = [...accumDatas];
          this.myStatisticsData.chartData.forecastSeries = [...forecastDatas];
          this.drawPointsChart();
          // this.dataLists = tableDatas;
          // this.chartData = tmpChartDatas;
          // this.drawLine();
        }else{
          this.drawDefultChart();
        }
       
      }).catch((err) => {
        this.loading = false;
        console.log(err);
      });
    },
    rptChange(e){
     
      this.drawDefultChart();
      this.columns=[];
      this.dataLists=[];
      this.getRptData();
    },
    yearChange(e){
        console.log(`yearChange = ${e}`);
        this.getRptData();
    },
    dateSelect(date){
        var a = moment(date);
        let v = parseInt(this.rpt);
        if(v==1){
          this.date = a.format('YYYY/MM/DD');
          console.log(this.date);
        }else if(v==2){
          this.date = a.format('YYYY/MM');
          console.log(this.date);
        }
        this.getRptData();
    },
    disabledDate(startValue) {
      console.log(`disabledDate1 = ${startValue}`);
      const endValue = moment();
      console.log(`disabledDate2 = ${endValue}`);
      if (!startValue || !endValue) {
        return false;
      }
      return (
        startValue.valueOf() > endValue.valueOf() ||
        startValue >= moment().endOf()
      );
    },
    drawDefultChart(){
      // let option =  this.getDefaultOption();
      // 基于准备好的dom，初始化echarts实例
      // let myChart = this.$echarts.init(document.getElementById("myChart"));
      // option && myChart.setOption(option, true);
      // myChart.resize();
      // this.resizeChart();
    },
    getDefaultOption(){
      let category =  ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
      if(parseInt(this.rpt)==3){
        category = this.ycategorys;
      }else if(parseInt(this.rpt)==2){
        category = this.mcategorys;
      }else if(parseInt(this.rpt)==1){
        category = this.dcategorys;
      }
     let option = {
          xAxis: {
              type: 'category',
              data: category,
          },
          yAxis: {
              type: 'value'
          },
          legend:{

          },
          series: [{
              data: [0],
              type: 'line',
              smooth: true
          }]
      };
      console.log(option);
      return option;
    },
    mselChange(){
      console.log("mselChange",this.msel);
      if(this.msel){
        this.defaultStatistic = 1;
      }else{
        this.defaultStatistic = 0;
      }
    },
    pointSelect(selectedKeys, info){
      console.log('selected', selectedKeys, info);
      // console.log(JSON.stringify(info))
      if(info.selected){
        let key = info.node.$vnode.data.key;
        this.checkedPoints.push(key);
      }else{
        //this.checkedPoints.reduce
        let key = info.node.$vnode.data.key;
        let arr = new Array();
        this.checkedPoints.forEach(element => {
          if(element!=key){
            arr.push(element);
          }
        });

        this.checkedPoints = arr;
      }
      this.clearPointDate();
    },
    pointCheck(checkedKeys, info){
      console.log('onCheck', checkedKeys, info);
      this.clearPointDate();
    },
    clearPointDate(){

      this.indeterminate = !!this.checkedPoints.length && this.checkedPoints.length < this.treePointNoLists.length;
      this.checkAll = this.checkedPoints.length === this.treePointNoLists.length;

      if(this.checkedPoints.length===1){
        if(parseInt(this.rpt)===3){
             this.mode="multiple";
             this.year = this.fiscalYear;
        }else{
           this.tue = undefined;
            this.wed = undefined;
            this.thu = undefined;
            this.fri = undefined;
            this.sat = undefined;
            this.sun = undefined;
            this.mon8 = undefined;
            this.mon9 = undefined;
            this.mon10 = undefined;
            this.mon11 = undefined;
            this.mon12 = undefined;
        }
      }else{
        if(parseInt(this.rpt)===1){
          this.tue = undefined;
          this.wed = undefined;
          this.thu = undefined;
          this.fri = undefined;
          this.sat = undefined;
          this.sun = undefined;
        }else if(parseInt(this.rpt)===2){
          this.tue = undefined;
          this.wed = undefined;
          this.thu = undefined;
          this.fri = undefined;
          this.sat = undefined;
          this.sun = undefined;
          this.mon8 = undefined;
          this.mon9 = undefined;
          this.mon10 = undefined;
          this.mon11 = undefined;
          this.mon12 = undefined;
        }else if(parseInt(this.rpt)===3){
          this.mode="default";
          // this.year = undefined;
          this.year = this.fiscalYear;
        }
      }
      
    },
    queryPoints(e){
        const value = e.target.value;
        console.log("queryPoints",value);
        if(value===''){
            this.treePointLists = [...this.pointLists];
            this.treePointNoLists.length=0;
            for(let i=0;i<this.treePointLists.length;i++){
              this.treePointNoLists.push(this.treePointLists[i].no);
            }
        }else{
            const points = [...this.pointLists];
            this.treePointLists = points.filter(function(item){
                console.log("queryPoints txt",item.text);
                if(new String(item.text).indexOf(value)>=0){
                  return true;
                }
            });
          this.treePointNoLists.length=0;
          for(let i=0;i<this.treePointLists.length;i++){
            this.treePointNoLists.push(this.treePointLists[i].no);
          }
             console.log("queryPoints treePointLists",this.treePointLists);
        }
    },
    monthRptYearChange(e){
        console.log(`monthRptYearChange = ${e}`);
        let now = moment();
        let star = new moment(e+"/01/01");;
        let len = 12;
        let days = new Array();
        if(e===now.year()){
          len  =  now.month()+1;
        }

        for(let i = 0;i<len;i++){
          let day = new moment(star);
          day = day.add(i, 'months');
          days.push(day);
        }
  
        this.mon = days.length>0?days[0]:undefined;
        this.tue = days.length>1?days[1]:undefined;
        this.wed = days.length>2?days[2]:undefined;
        this.thu = days.length>3?days[3]:undefined;
        this.fri = days.length>4?days[4]:undefined;
        this.sat = days.length>5?days[5]:undefined;
        this.sun = days.length>6?days[6]:undefined;
        this.mon8 = days.length>7?days[7]:undefined;
        this.mon9 = days.length>8?days[8]:undefined;
        this.mon10 = days.length>9?days[9]:undefined;
        this.mon11 = days.length>10?days[10]:undefined;
        this.mon12 = days.length>11?days[11]:undefined;
        this.days = [...days];
        
    },
    weekChange(date,str){
      if(date!=null){
        let num = date.weekday();
        let star = date.subtract(num, 'days');
        let days = new Array();
        for(let i = 0;i<7;i++){
          let day = new moment(star);
          day = day.add(i, 'days');
          days.push(day);
        }
  
        this.mon = days[0];
        this.tue = days[1];
        this.wed = days[2];
        this.thu = days[3];
        this.fri = days[4];
        this.sat = days[5];
        this.sun = days[6];
        this.days = [...days];

      }else{
        this.mon = undefined;
        this.tue = undefined;
        this.wed = undefined;
        this.thu = undefined;
        this.fri = undefined;
        this.sat = undefined;
        this.sun = undefined;
        this.days = [];
      }
    },
    drawMyChart(e){
      console.log('drawMyChart',e);
      let kind = e.target.value;
      this.drawPointsChart();
      // if(kind==='pie'){
      //     this.drawPointsPieChart();
      // }else{
      // }
    },
    drawPointsChart(){
       if(this.myStatisticsData.chartData.categorys.length>0 && this.myStatisticsData.chartData.series.length>0){
         let allseries = [...this.myStatisticsData.chartData.series];
         let mseries = new Array();
         let legends = new Array();
         let title = "";
         let chartKind = this.chartKind;
         let rtv = parseInt(this.rpt);
         let accumSeries = new Array();
         let  yAxiData= new Array();
         yAxiData.push({
            type: 'value',
            name: this.$t("energy.enedata009")+'('+this.myStatisticsData.chartData.unit+')',
            axisLine: {
              show: true,
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              color: '#999'
            },
        });


         if(this.checkedPoints.length==1){
              if(rtv===3){
                  if(this.year.length>1){
                      for(let k =0;k<this.year.length;k++){
                        let d = this.year[k];
                        for(let index = 0; index < allseries.length; index++) {
                            let item = allseries[index];
                            if(legends.filter(function(ele){
                              return ele === item.name;
                            }).length>0){
                              continue;
                            }

                            if(item.year===d){
                                item.type = chartKind;
                                legends.push(item.name);
                                title= item.pointName;
                                if(index<colors.lenth){
                                  item.color =  colors[index];
                                }
                                mseries.push(item);
                            }
                        }
                    }
                  }else{
                      for (let index = 0; index < allseries.length; index++) {
                          let item = allseries[index];
                          item.type = chartKind;
                          legends.push(item.name);
                          title= item.pointName;
                          if(index<colors.length){
                            item.color =  colors[index];
                          }
                          mseries.push(item);
                      }
                  }
              }else if(rtv===2){
                  if(this.days.length>1){
                      for(let k =0;k<this.days.length;k++){
                          let d = this.days[k];
                          for(let index = 0; index < allseries.length; index++) {
                              let item = allseries[index];
                              if(legends.filter(function(ele){
                                return ele === item.name;
                              }).length>0){
                                continue;
                              }
                              if(item.year===d.year() && item.month===(d.month()+1)){
                                  item.type = chartKind;
                                  legends.push(item.name);
                                  title= item.pointName;
                                  if(index<colors.lenth){
                                    item.color =  colors[index];
                                  }
                                  mseries.push(item);
                              }
                          }
                      }
                  }else{
                      for (let index = 0; index < allseries.length; index++) {
                          let item = allseries[index];
                          item.type = chartKind;
                          legends.push(item.name);
                          title= item.pointName;
                          if(index<colors.length){
                            item.color =  colors[index];
                          }
                          mseries.push(item);
                      }
                  }
              }else if(rtv===1){
                  for (let index = 0; index < allseries.length; index++) {
                      let item = allseries[index];
                      item.type = chartKind;
                      legends.push(item.name);
                      title= item.pointName;
                      if(index<colors.length){
                        item.color =  colors[index];
                      }
                      mseries.push(item);
                  }
                  if(this.days.length===1){
                      if(this.chartKind === 'bar'){
                            for (let index = 0; index < mseries.length; index++) {

                                let mdata = mseries[index];
                                let mlist = mdata.data; 
                                let mv = 0;
                                let ndata = new Array();
                              
                                for (let j = 0; j < mlist.length; j++) {
                                    mv+=mlist[j];
                                    let nv = new Number(mv);
                                    ndata.push(nv.toFixed(2));
                                }

                                
                                let nitem = {
                                    name: mdata.name + "-" + this.$t("energy.enedata007"),
                                    data: ndata,
                                    type: "line",
                                    yAxisIndex: 1,
                                    smooth: true
                                };//this.myStatisticsData.chartData.
                                accumSeries.push(nitem);
                            }
                      }
                  }

             }

            if(accumSeries.length>0 && this.chartKind=='bar' && this.days.length===1){
                mseries.push(...accumSeries);
                accumSeries.forEach(element => {
                    legends.push(element.name);
                });

               
                
                let yAxi1 =  {
                    type: 'value',
                    name: this.$t("energy.enedata009")+'('+this.myStatisticsData.chartData.unit+')',
                    axisLine: {
                      show: true,
                    },
                    axisTick: {
                      show: false
                    },
                    // axisLabel: {
                    //   color: '#999'
                    // },
                };
                  
                let yAxi2 = {
                    type: 'value',
                    name: this.$t("energy.enedata007")+'('+this.myStatisticsData.chartData.unit+')',
                    nameLocation: 'end',
                    inverse: false,
                    axisLine: {
                      show: true,
                    },
                    axisTick: {
                      show: false
                    },
                    // axisLabel: {
                    //   color: '#999'
                    // },
                } ;
                yAxiData = new Array();
                yAxiData.push(yAxi1);
                yAxiData.push(yAxi2);
            }

            let now = moment();
            let sidx = -1;
            for (let index = 0; index < mseries.length; index++) {
                let item = mseries[index];
                if(index<colors.length){  
                  item.color = colors[index];
                }
                console.log("item detail:",item,item.year,now.year(),item.month,(now.month()+1),(item.type ==="line" && item.year===now.year() && item.month === (now.month()+1)));
                if(item.type ==="line" && item.year===now.year() && item.month === (now.month()+1)){
                  sidx = index;
                }
            }

            if(sidx>=0){
                if(rtv==2 && this.chartKind=='bar' && this.days.length===1 && this.days[0].year()===now.year() && this.days[0].month()===now.month()){
                  let sTag = now.date();
                  if(sTag>-1){
                      let vm = {
                        show: true,
                        top:'center',
                        dimension:0,
                        seriesIndex:sidx,
                        pieces: [{
                            label:this.$t('energy.enedata011'),
                            lte: sTag,
                            color: '#0e80f5'
                        },{
                            label:this.$t('energy.enedata010'),
                            gt: sTag,
                            lte: now.daysInMonth(),
                            color: '#fc8858'
                        }]
                      };
                      this.myStatisticsData.chartData.visualMap = vm;
                  }
                }
            }else{
                this.myStatisticsData.chartData.visualMap = undefined;
            }
        }else{
            if(rtv===3){
              let cyear = this.year;
              mseries = allseries.filter(function(item){
                  return item.year == cyear;
              });
              title = cyear;
            }else if(rtv===2){
              let day = this.mon;
              mseries = allseries.filter(function(item){
                  return day.year() == item.year && day.month()+1 == item.month ;
              });
              title = day.format('YYYY/MM');
            }else if(rtv===1){
              let day = this.mon;
              mseries = allseries.filter(function(item){
                  return day.year() == item.year && day.month()+1 == item.month &&  day.date() == item.day;
              });
              title = day.format('YYYY/MM/DD');
            }


            
            for (let index = 0; index < mseries.length; index++) {
                let item = mseries[index];
                item.type = chartKind;
                let  pname = item.name;
                item.sname = pname;
                item.name = item.pointName;
                legends.push(item.name);
                if(index<colors.length){  
                  item.color = colors[index];
                }
            }
         }
         if(this.chartKind=='pie'){
              this.drawPieChart(title,this.myStatisticsData.chartData.categorys,mseries,legends,yAxiData);
         }else{
              this.drawEChart(title,this.myStatisticsData.chartData.categorys,mseries,legends,yAxiData);
         }
       }
    },
    drawEChart(title,categorys,mseries,legends,yAxiData){
    
      // 绘制图表
      let option = null;
      if(this.chartKind=='pie'){

      }else{
        option = {
            backgroundColor: '#ffffff',
            title: {
                text: title,
                textStyle: {
                  color: colors[0],
                },
            },
            toolbox: {
              show: true,
              orient: 'horizontal',
              left: 'right',
              top: 'top',
              feature: {
                mark: { show: true },
                dataView: { show: false, readOnly: false },
                magicType: { show: true, type: ['line', 'bar', 'stack'] },
                restore: { show: true },
                saveAsImage: { show: true}//,title:this.$t('energy.enedata010')
              }
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                // type: 'shadow'
                type: 'cross'
              },
              // formatter: '{b} : <br/> {a}: {c}'+this.myStatisticsData.chartData.unit
            },
            // tooltip: {
            //   trigger: 'axis'
            // },
            legend: {
                data: legends,
                orient: 'horizontal',
                type: "scroll",
                // right: 10,
                top: "bottom",
                left: "left",
                // textStyle: {
                //   color: '#ccc'
                // },
            },
            xAxis: {
                type: 'category',
                data: categorys,
                axisLine: {
                  show: true,
                },
                axisTick: {
                  show: false
                },
                // axisLabel: {
                //   color: '#999'
                // },
                
            },
            yAxis:yAxiData,
            series:mseries,
            visualMap:this.myStatisticsData.chartData.visualMap,
        };
      }

    
     if(this.myChart){
        this.myChart.dispose();
      }
      let that = this;
      // 基于准备好的dom，初始化echarts实例
      this.myChart = this.$echarts.init(document.getElementById("myChart"));//,'dark'
      // 绘制图表
      this.myChart.on('click', function (event) {
          console.log("event >>>",event);
          if (event.data) {
            console.log("event data>>>",event.data);
          }
          that.fixPointValueInfo(event);
      });
      // console.log(">>>",option);
      option && this.myChart.setOption(option, true);
      this.resizeChart();
    
      let resizeDiv = document.getElementById("myChart");
      let chart =  this.myChart;
      let listener = () => {
        chart.resize();
      };
      EleResize.on(resizeDiv, listener);
      
    },
    drawPointsPieChart(){

    },
    drawPieChart(title,categorys,mseries,legends,yAxiData){
      let products =  new Array();
      products.push('product');
      products.push(...categorys);
      let sdata =  new Array();
      let rseries = new Array();
      mseries.forEach(element => {
        let arr = new Array();
        arr.push(element.name);
        arr.push(...element.data);
        sdata.push(arr);

        rseries.push({
        type: 'line',
        smooth: true,
        seriesLayoutBy: 'row',
        emphasis: { focus: 'series' }
      });
      });
      // let myChart = this.$echarts.init(document.getElementById("myChart"));//,'dark'
      let unit = this.myStatisticsData.chartData.unit;
      let formatter = '{rcs|{b}：{@'+products[1]+'}'+this.myStatisticsData.chartData.unit+' ({d}%)}';
      // let formatter2 = '{b} {a} ：{@'+products[1]+'}'+this.myStatisticsData.chartData.unit+' ({d}%)';
      let option = {
          backgroundColor: '#ffffff',
          title: {
              text: title,
              textStyle: {
                color: colors[0],
              },
          },
          color:colors,
          legend: {
                orient: 'vertical',
                type: "scroll",
                // right: 10,
                top: "top",
                left: "right",
              },
          tooltip: {
              trigger: 'axis',
              showContent: true,
              axisPointer: {
                type: 'cross'
              },
              position: function (pos, params, el, elRect, size) {
                var obj = { top: 10 };
                obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
                return obj;
              }
          },
          dataset: {
            source: [
              products,
              ...sdata
            ]
          },
          xAxis: { type: 'category' },
          yAxis: { gridIndex: 0 
            ,type: 'value',
            name: this.$t("energy.enedata009")+'('+unit+')',
            axisLine: {
              show: true,
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              color: '#999'
            }},
          grid: { top: '60%' },
          series: [
            ...rseries,
            {
              type: 'pie',
              id: 'pie',
              radius: '30%',
              center: ['50%', '25%'],
              emphasis: {
                focus: 'self'
              },
              top:20,
              label: {
                alignTo: 'labelLine',
                formatter: formatter,
                rich:{rcs:{padding: 20,height: 30}}
              },
              encode: {
                itemName: 'product',
                value: products[1],
                tooltip:products[1]
              },
              
            },
          ]
      };
      //myChart.on('updateAxisPointer', function (event) {

      if(this.myChart){
        this.myChart.dispose();
      }
      this.myChart = this.$echarts.init(document.getElementById("myChart"));//,'dark'
      let chart = this.myChart;
      chart.on('updateAxisPointer', function (event) {
        const xAxisInfo = event.axesInfo[0];
        if (xAxisInfo) {
          const dimension = xAxisInfo.value+1;
          chart.setOption({
            series: {
              id: 'pie',
              type:'pie',
              label: {
                formatter: '{b}：{@[' + dimension + ']}'+unit+' ({d}%)'
              },
              encode: {
                value: dimension,
                tooltip: dimension
              }
            }
          });
        }
      });
      option && chart.setOption(option,true);
      this.resizeChart();
      let resizeDiv = document.getElementById("myChart");
      let listener = () => {
        chart.resize();
      };
      EleResize.on(resizeDiv, listener);
    },  
    fixPointValueInfo(event){
      this.discascade = false;
      console.log('fixPointValueInfo1>',event.seriesIndex);
      console.log('fixPointValueInfo2>',this.myChart._model.option.series);
      let point = this.myChart._model.option.series[event.seriesIndex];
      if(this.chartKind =='bar' && this.chartKind != point.type){
        // this.$message.error('累计折线图数据不可修改。');
        return;
      }
      let year  = 0;
      let month = 0;
      let day   = 0;
      let hour  = 0;
      let date = '';
      let now = new moment();
      let nowMonth = now.month()+1;
      let nowYear  = now.year();
      let nowDay  = now.date();
      let nowHour  = now.hour();
      let cascade = (this.rpt!='3');
      console.log('measurementStatisticsData>',point);
      let modify = true;
      
      if(this.rpt == '3'){
        year = point.year;
        if(this.defaultStatistic==0){
          month = event.dataIndex + 4;
        }else{
          month = event.dataIndex + parseInt(this.month);
        }
        if(month>12){
          year +=1;
          month = month-12;
        }
        if((nowYear < year) || (nowYear === year && nowMonth<=month)){
          modify = false;
          cascade = false;
          this.discascade = true;
          // this.$message.error('当前日期和未来日期的使用量不可修改。');
          // return;
        }
        date = year+"/"+(month>9?month:'0'+month);
      } else if(this.rpt == '2'){
        year  = point.year;
        month = point.month;
        day   = event.dataIndex + 1;
        date = year+"/"+(month>9?month:'0'+month)+"/"+(day>9?day:'0'+day);
        if((nowYear < year) || (nowYear == year && nowMonth<month)){
          modify = false;
          cascade = false;
          this.discascade = true;
          // this.$message.error('当前日期和未来日期的使用量不可修改。');
          // return;
        }else if(nowYear == year && nowMonth==month && nowDay<=day){
          modify = false;
          cascade = false;
          this.discascade = true;
          // this.$message.error('当前日期和未来日期的使用量不可修改。');
          // return;
        }
        if(nowYear == year && nowMonth==month ){
          cascade = false;
          this.discascade = true;
        }
      } else if(this.rpt == '1'){
        year  = point.year;
        month = point.month;
        day   = point.day;
        hour  = event.dataIndex + 1;
        if((nowYear < year) || (nowYear == year && nowMonth<month)){
          modify = false;
          cascade = false;
          this.discascade = true;
          // this.$message.error('当前日期和未来日期的使用量不可修改。');
          // return;
        }else if(nowYear == year && nowMonth==month && nowDay<day){
          modify = false;
          cascade = false;
          this.discascade = true;
          // this.$message.error('当前日期和未来日期的使用量不可修改。');
          // return;
        }else if(nowYear == year && nowMonth==month && nowDay==day && nowHour<=hour){
          modify = false;
          cascade = false;
          this.discascade = true;
          // this.$message.error('当前日期和未来日期的使用量不可修改。');
          // return;
        }
        if(nowYear == year && nowMonth==month && nowDay==day){
          cascade = false;
          this.discascade = true;
        }

        date = year+"/"+(month>9?month:'0'+month)+"/"+(day>9?day:'0'+day)+" "+(hour>9?hour:'0'+hour)+":00:00";
      }
      let fixMode = true;
      if(point.pointType == 6 && this.rpt == '1'){
          fixMode = false;
      }

      let fixAutoMode = false;
      if(point.pointType == 4 && this.rpt != '3'){
          fixAutoMode = true;
      }

      let dayValue = 0;
      let nightValue = 0;
      if(point.daytimeData){
        dayValue = point.daytimeData[event.dataIndex];
      }

      if(point.nightData){
        nightValue = point.nightData[event.dataIndex];
      }

      let data = {
              date:date,
              value:event.data,
              dayValue:dayValue,
              nightValue:nightValue,
              dataIndex:event.dataIndex,
              name:event.name,
              seriesName:event.seriesName,
              seriesIndex:event.seriesIndex,
              pointId: point.pointId,
              pointType: point.pointType,
              pointName: point.pointName,
              unit : point.unit,
              fixMode:fixMode,
              cascade:cascade,
              fixAutoMode:fixAutoMode,
              modify:modify,
              reportType: this.rpt,

              params : {
                action:1,
                clientId: this.$store.getters.clientId,
                siteId: this.$route.query.id,
                sitegrId: this.$store.getters.sitegrId,
                tenantId: 0,
                reportType: this.rpt,
                defaultStatistic:this.defaultStatistic,
                year:year,
                month:month,
                day:day,
                hour:hour,
                pointId: point.pointId,
                pointType: point.pointType,
                value:event.data,
                dayValue:dayValue,
                nightValue:nightValue,
                fixMode:fixMode,
            },

          };
          this.fixPointInfo = data;
          this.initfixPointInfo = JSON.parse(JSON.stringify(data));
          this.showModal();
    },
    showModal() {
        this.visible = true;
        if(this.$refs.ruleForm){
            this.$refs.ruleForm.resetFields();
        }
    },
    saveChange(){
      if(this.fixPointInfo.fixMode){
        this.save();
      }else{

        this.$refs.ruleForm.validate(valid => {
                 if (valid) {
                      this.save();
                 } else {
                     console.log('error submit!!');
                     return false;
                 }
             });
      }
    },
    save() {
      // console.log(e);
      // this.visible = false;
      let year  = 0;
      let month = 0;
      let day   = 0;
      let hour  = 0;
      let date = '';
      let now = new moment();
      let nowMonth = now.month()+1;
      let nowYear  = now.year();
      let nowDay  = now.date();
      let nowHour  = now.hour();
      let param = this.fixPointInfo.params;
      let dataIndex = parseInt(this.fixPointInfo.dataIndex);

      param.fixMode      = this.fixPointInfo.fixMode;
      param.fixAutoMode  = this.fixPointInfo.fixAutoMode?1:0;
      param.cascade      = this.fixPointInfo.cascade;

      param.nightValue   = this.fixPointInfo.nightValue?parseFloat(this.fixPointInfo.nightValue):0;
      param.dayValue     = this.fixPointInfo.dayValue?parseFloat(this.fixPointInfo.dayValue):0;
      param.value        = this.fixPointInfo.value?parseFloat(this.fixPointInfo.value):0;

      if(!param || dataIndex<0){
         this.$message.error(this.$t('energy.enedata567'));
        return;
      }
      if(this.rpt == '3' && dataIndex>=0){
        year = param.year;
        if(this.defaultStatistic==0){
          month = dataIndex + 4;
        }else{
          month = dataIndex + parseInt(this.month);
        }
        if((nowYear < year) || (nowYear === year && nowMonth<=month)){
          this.$message.error(this.$t('energy.enedata572'));//'当前日期和未来日期的使用量不可修改。'
          return;
        }
        date = year+"/"+(month>9?month:'0'+month);

       if(!param.fixMode && this.fixPointInfo.pointType !=6){
          if(param.value != (param.dayValue+param.nightValue)){
            this.$message.error('使用量不等于白天使用量与夜间使用量之和。');//this.$t('energy.enedata572')
            return;
          }
        }
      } else if(this.rpt == '2' && dataIndex>=0){
        year  = param.year;
        month = param.month;
        day   = dataIndex + 1;
        date = year+"/"+(month>9?month:'0'+month)+"/"+(day>9?day:'0'+day);
        if((nowYear < year) || (nowYear == year && nowMonth<month)){
          this.$message.error(this.$t('energy.enedata572'));//this.$message.error('当前日期和未来日期的使用量不可修改。');
          return;
        }else if(nowYear == year && nowMonth==month && nowDay<=day){
          this.$message.error(this.$t('energy.enedata572'));//this.$message.error('当前日期和未来日期的使用量不可修改。');
          return;
        }

        if(!param.fixMode && this.fixPointInfo.pointType !=6){
          if(param.value != (param.dayValue+param.nightValue)){
            this.$message.error('使用量不等于白天使用量与夜间使用量之和。');//this.$t('energy.enedata572')
             return;
          }
        }
      } else if(this.rpt == '1' && dataIndex>=0){
        year  = param.year;
        month = param.month;
        day   = param.day;
        hour  = dataIndex + 1;
        if((nowYear < year) || (nowYear == year && nowMonth<month)){
          this.$message.error(this.$t('energy.enedata572'));//this.$message.error('当前日期和未来日期的使用量不可修改。');
          return;
        }else if(nowYear == year && nowMonth==month && nowDay<day){
          this.$message.error(this.$t('energy.enedata572'));// this.$message.error('当前日期和未来日期的使用量不可修改。');
          return;
        }else if(nowYear == year && nowMonth==month && nowDay==day && nowHour<=hour){
          this.$message.error(this.$t('energy.enedata572'));//this.$message.error('当前日期和未来日期的使用量不可修改。');
          return;
        }

        date = year+"/"+(month>9?month:'0'+month)+"/"+(day>9?day:'0'+day)+" "+(hour>9?hour:'0'+hour)+":00:00";
      }else{
        this.$message.error(this.$t('energy.enedata567'));
        return;
      }

      console.log("param>>>",param);
      this.$confirm({
          title:this.$t("energy.enedata184"),
          centered: true,
          onOk: () => {
              this.floading = true;
              fixUpdateEnergyData(param)
              .then((res) => {
                  console.log(res);
                  this.floading = false;
                  if("03"===res.errorCode ){
                    this.$message.success(res.msg);
                    this.visible = false;
                    this.getRptData();

                  }else{
                    this.$message.error(res.msg);
                  }
                
              }).catch((err) => {
                  this.floading = false;
                  console.log(err);
              });
          }
      });
     
      
    },
    getReportType(kind){
      let name = '';
      if(kind){
        this.rptKinds.forEach
         this.rptKinds.forEach(element => {
           if(element.no === kind){
             name = element.text;
           }
        });
      }  
      return name;
    }

  },
  destroyed(){
    window.removeEventListener("resize", () => this.resizeChart());
  },
  components: {
    'sub-menu': SubMenu,
  },
}
</script>

<style scoped>
.show{
 display: block;
}
.hide{
  display:none;  
}
#energyPointStatistics {
  width: 100%;
  height: 100%;
  padding: 10px 20px;
}
.energyPointStatistics-title {
  display: flex;
  align-items: center;
  font-size: 20px;
  margin-bottom: 10px;
  color: #3e3a39;
}
.energyPointStatistics-title::before {
  content: "";
  display: inline-block;
  width: 4px;
  height: 30px;
  background: #7585cd;
  margin-right: 20px;
}
.display-between {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 8px
}
.display-between-2 {
  display: flex;
  justify-content: flex-start;
}
.display-column {
  width: 30%;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}
.display-column span {  
  font-size: 14px;
  margin-bottom: 5px;
}
.inquire {
  padding:0 10px;
  margin-bottom: 10px;
}
.inquire-item {
  width: 100%;
}
.chart-box{
  /* background: #fff; */
  /* height: calc(100% -200px); */

  height: 100%;
  padding: 5px;
  margin: 10px 5px;
}


.table-box{
  height: 100%;
  padding: 5px;
  margin: 10px 5px;
  background: #fff;
} 
.date-box{
   font-size: 14px;
   padding: 10px;
   border-bottom: 1px solid #d9d9d9;
}
.date-box:last-child{border:none}

.date-box1{
   padding: 10px 5px;
   font-size: 14px;
   border-bottom: 1px solid #d9d9d9;
}
.date-box1:last-child {border-bottom: 1px solid #d9d9d9;}
.ant-carousel >>> .slick-slide {
  text-align: center;
  height: 100%;
  /* line-height: 160px; */
  background: #7682CE;
  overflow: hidden;
}

.ant-carousel >>> .custom-slick-arrow {
  width: 25px;
  height: 25px;
  font-size: 25px;
  color: #fff;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.3;
}
.ant-carousel >>> .custom-slick-arrow:before {
  display: none;
}
.ant-carousel >>> .custom-slick-arrow:hover {
  opacity: 0.5;
}

.ant-carousel >>> .slick-slide h3 {
  color: #fff;
}
.point-tree-box{width: 100%;height: 100%;padding:10px;border: 1px solid #d9d9d9 ;}
.point-tree{width: 100%;height:calc(100% - 110px);overflow: auto;}
.point-date-box{width: 100%;height: 100%;padding:10px;border: 1px solid #d9d9d9 ;}
.point-date{width: 100%;height:100%;overflow: auto;}
.ant-switch-checked {
    background-color: #7682CE;
}
fix-modal{
  width:600px; height:720px; overflow: hidden;
}
fix-modal-content{
  width: 100%;height: 100%;overflow: auto;
}

ul>li::before {
    content: '•';
    /* margin-left: 5px; */
    margin-right: 5px;
}
</style>